import React from 'react';
import styled, { css } from 'styled-components';
import Layout from '../components/Layout';

type BasePagePropsType = {
  className?: string;
};

const BasePage: React.FC<BasePagePropsType> = ({ className }) => {
  return (
    <div className={className}>
      <Layout>
        <div style={{ padding: '14.1vh 0', textAlign: 'center' }}>
          <span>お探しのページが見つかりませんでした</span>
        </div>
      </Layout>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default Page;
